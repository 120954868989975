'use client';
import { Center, Container } from '@mantine/core';
interface Props {
  children: React.ReactNode;
}
export default function AuthLayout({ children }: Props) {
  return (
    <Center
      sx={{
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(to top, #ccc 0%, #ccc 100%)',
      }}
    >
      <Container size="xs" sx={{ width: 480, paddingBottom: 16 }}>
        {children}
      </Container>
    </Center>
  );
}
